import { lazy } from "react";

// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import UserManagement from "views/pages/dashboard/user-management";

// page routing
const NotFoundPage = Loadable(lazy(() => import("views/pages/maintenance/Error")));

const StreamDashboard = Loadable(lazy(() => import("views/pages/dashboard/stream")));
const StreamDashboardTest = Loadable(lazy(() => import("views/pages/dashboard/stream-test")));
const EntityDashboard = Loadable(lazy(() => import("views/pages/dashboard/entity")));
const EntityDetails = Loadable(lazy(() => import("views/pages/dashboard/entity-details")));
const StreamScanning = Loadable(lazy(() => import("views/pages/dashboard/stream-scanning")));
const StreamSignal = Loadable(lazy(() => import("views/pages/dashboard/stream-signals")));
const SignalTypeDetails = Loadable(lazy(() => import("views/pages/dashboard/signal-type-details")));
const TradeBrokerDetails = Loadable(lazy(() => import("views/pages/dashboard/trade-broker")));
const TradeAccountDetails = Loadable(lazy(() => import("views/pages/dashboard/trade-accounts")));
const TradePlays = Loadable(lazy(() => import("views/pages/dashboard/trade-plays")));
const TradePlayDetails = Loadable(lazy(() => import("views/pages/dashboard/trade-play-details")));
const TradingSystems = Loadable(lazy(() => import("views/pages/dashboard/trading-systems")));
const TradingSystemDetails = Loadable(lazy(() => import("views/pages/dashboard/trading-system-details")));

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <StreamDashboard />,
    },
    {
      path: "/stream-dashboard",
      element: <StreamDashboard />,
    },
    {
      path: "/stream-dashboard-test",
      element: <StreamDashboardTest />,
    },
    {
      path: "/entity-dashboard",
      element: <EntityDashboard />,
    },
    {
      path: "/entity-details/:entity",
      element: <EntityDetails />,
    },
  
    {
      path: "/stream-scanning",
      element: <StreamScanning />,
    },
    {
      path: "/stream-signal",
      element: <StreamSignal />,
    },
    {
      path: "/signal-type-details/:id",
      element: <SignalTypeDetails />,
    },
    {
      path: "/trade-dashboard/brokers",
      element: <TradeBrokerDetails />,
    },
    {
      path: "/trade-dashboard/brokers/:broker/accounts",
      element: <TradeAccountDetails />,
    },
    {
      path: "/trade-play",
      element: <TradePlays />,
    },
    {
      path: "/trading-systems",
      element: <TradingSystems />,
    },
    {
      path: "/trading-systems/:trade",
      element: <TradingSystemDetails />,
    },
    {
      path: "/trade-dashboard/brokers/:broker/accounts/:account/trade-play/details",
      element: <TradePlayDetails />,
    },
    {
      path: "/user-management",
      element: <UserManagement />,
    },
    {
      path: "/*",
      element: <NotFoundPage />,
    },
  ],
};

export default MainRoutes;
