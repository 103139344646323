export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const GET_DASHBOARD_ENTITIES_SUCCESS = "GET_DASHBOARD_ENTITIES_SUCCESS";
export const GET_DASHBOARD_ENTITY_VALUE_SUCCESS = "GET_DASHBOARD_ENTITY_VALUE_SUCCESS";
export const GET_DASHBOARD_ENTITY_STATS_SUCCESS = "GET_DASHBOARD_ENTITY_STATS_SUCCESS";
export const GET_IDENTIFIER_SIGNAL_SUCCESS = "GET_IDENTIFIER_SIGNAL_SUCCESS";
export const GET_IDENTIFIER_VARIABLE_SUCCESS = "GET_IDENTIFIER_VARIABLE_SUCCESS";
export const ADD_QUERY_SUCCESS = "ADD_QUERY_SUCCESS";
export const EDIT_QUERY_SUCCESS = "EDIT_QUERY_SUCCESS";
export const DELETE_QUERY_SUCCESS = "DELETE_QUERY_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";
