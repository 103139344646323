import { streamBluePrintSignal } from "types/dashboard";
import {
  ADD_SIGNAL_TYPE_SUCCESS,
  BLUEPRINT_FAILURE,
  BLUEPRINT_REQUEST,
  DELETE_SIGNAL_TYPE_SUCCESS,
  EDIT_SIGNAL_TYPE_SUCCESS,
  GET_SIGNAL_TYPE_SUCCESS
} from "./actionType";

const initialState = {
  loading: false,
  hasError: false,
  signalData: [],
  deletedSignalData: {}
};

export function streamBlueprintReducer(state = initialState, action: any) {
  switch (action.type) {
    case BLUEPRINT_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case GET_SIGNAL_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        signalData: action.payload
      };
    case ADD_SIGNAL_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case EDIT_SIGNAL_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case DELETE_SIGNAL_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        deletedSignalData: action.payload
      };
    case BLUEPRINT_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
