// material-ui
import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

// assets
import useConfig from "hooks/useConfig";
import { useEffect, useState } from "react";

// project imports
import Iconify from "ui-component/iconify/Iconify";

const HeaderAvatarStyle = styled(Avatar)(({ theme }) => ({
  ...theme.typography.commonAvatar,
  ...theme.typography.mediumAvatar,
  background: theme.palette.mode === "dark" ? theme.palette.dark.main : theme.palette.secondary.light,
  color: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
  "&:hover": {
    background: theme.palette.mode === "dark" ? theme.palette.secondary.main : theme.palette.secondary.dark,
    color: theme.palette.mode === "dark" ? theme.palette.secondary.light : theme.palette.secondary.light,
  },
}));

const ThemeSection = () => {
  const { navType, onChangeMenuType } = useConfig();

  const [isDarkTheme, setDarkTheme] = useState(false);

  const handleListItemClick = () => {
    onChangeMenuType(isDarkTheme ? "light" : "dark");
    setDarkTheme(!isDarkTheme);
  };

  useEffect(() => {
    setDarkTheme(navType === "dark");
  }, [navType]);

  return (
    <>
      <HeaderAvatarStyle variant="rounded" onClick={handleListItemClick} sx={{ mx: 1 }}>
        {isDarkTheme ? (
          <Iconify icon="ri:sun-line" width={24} height={24} />
        ) : (
          <Iconify icon="ri:moon-fill" width={24} height={24} />
        )}
      </HeaderAvatarStyle>
    </>
  );
};
export default ThemeSection;
