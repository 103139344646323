import axiosServices from "utils/axios";
import base64 from "base-64";

const username = process.env.REACT_APP_USERNAME;
const password = process.env.REACT_APP_PASSWORD;

export const loginService = async (email: string, pass: string) => {
  return await axiosServices.get(`/v1/api/user/log-in`, {
    headers: {
      Authorization: "Basic " + base64.encode(username + ":" + password),
    },
    params: {
      username: email,
      password: pass,
    },
  });
};
