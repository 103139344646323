// third-party
import { combineReducers } from "redux";

// project imports
import snackbarReducer from "./slices/snackbar";
import menuReducer from "./slices/menu";
import { dashboardStreamReducer } from "./StreamDashboard/reducer";
import { dashboardEntityReducer } from "./EntityDashboard/reducer";
import { signalSearchReducer } from "./SignalSearch/reducer";
import { dashboardTradeReducer } from "./TradeDashboard/reducer";
import { streamBlueprintReducer } from "./StreamBlueprint/reducer";
import { userDashboardReducer } from "./UsersDashborad/reducer";

const reducer = combineReducers({
  snackbar: snackbarReducer,
  menu: menuReducer,
  dashboardStream: dashboardStreamReducer,
  dashboardEntity: dashboardEntityReducer,
  signalSearch: signalSearchReducer,
  dashboardTrade: dashboardTradeReducer,
  streamBlueprint: streamBlueprintReducer,
  userDashboard: userDashboardReducer
});

export default reducer;
