// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconDashboard } from "@tabler/icons";

import { NavItemType } from "types";

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDashboard,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
// https://codedthemes.gitbook.io/berry/

const system: NavItemType = {
  id: "sample-docs-roadmap",
  title: <FormattedMessage id="System Management" defaultMessage="System Management" />,
  icon: IconBrandChrome,
  type: "group",
  children: [
   
   
    {
      id: "roadmap",
      title: <FormattedMessage id="User Management" defaultMessage="User Management" />,
      type: "item",
      url: "/user-management",
      icon: icons.IconSitemap,
      // external: true,
      // target: true,
    }
  ],
};

export default system;
