import React, { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";

// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";

// types
import { InitialLoginContextProps, JWTContextType } from "types/auth";

// service
import { loginService } from "services/Authentication/auth.service";

const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const getCookie = (name: string) => {
  let value = "; " + document.cookie;
  let parts = value.split("; " + name + "=");
  if (parts && parts.length === 2) {
    return parts.pop()?.split(";")?.shift();
  }
};

const verifyExpiry: () => boolean = () => {
  const time = getCookie("expires");
  return Number(time) > Date.now();
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        let currentUser = localStorage.getItem("currentUser");
        if (currentUser && verifyExpiry()) {
          const user = JSON.parse(currentUser);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string, rememberMe: boolean) => {
    const response = await loginService(email, password);
    const responseUser = response.data;

    const user = {
      email: responseUser.email,
      name: `${responseUser.firstName} ${responseUser.lastName}`,
    };

    let expires = Date.now() + 86400 * 1000;
    if (rememberMe) {
      expires = expires * 6;
    }

    document.cookie = `expires=${expires};`;

    localStorage.setItem("currentUser", JSON.stringify(user));

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (window.localStorage.getItem("users") !== undefined && window.localStorage.getItem("users") !== null) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    document.cookie = "expires=;";
    dispatch({ type: LOGOUT });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
