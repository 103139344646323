import {
  DASHBOARD_REQUEST,
  GET_DASHBOARD_USERDTOS_SUCCESS,
  DELETE_DASHBOARD_USERDTOS_SUCCESS,
} from "./actionType";

import { TUserDto } from "types/users";

const initialState = {
  loadingSignal: false,
  loadingUsers: false,
  signalHasError: false,
  userHasError: false,
  entityValueData: [],
  entityStatData: [],
  signalData: [],
  usersData : [] as TUserDto[],
  identSignalData: [],
  identVarsData: [],
};

export function userDashboardReducer(state = initialState, action: any) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loadingUsers: true,
        hasError: false,
      };
    case GET_DASHBOARD_USERDTOS_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        hasError: false,
        usersData: action.payload,
      };
    case DELETE_DASHBOARD_USERDTOS_SUCCESS:
      const filterData = state.usersData.filter(item => item.userId !== action.payload);
      return {
        ...state,
        loadingUsers: false,
        hasError: false,
        usersData: filterData,
      };      
    default:
      return state;
  }
}
