import { Dispatch } from "redux";
import {
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,

  GET_DASHBOARD_USERDTOS_SUCCESS,
  DELETE_DASHBOARD_USERDTOS_SUCCESS,
  CREATE_DASHBOARD_USERDTOS_SUCCESS,
} from "./actionType";
import {
  usersDtosApi, userDeleteApi, userCreateApi,
} from "services/Users/users.service";
import { TUserDto, TUserEntity } from "types/users";

const request = () => {
  return { type: DASHBOARD_REQUEST };
};

const failure = () => {
  return { type: DASHBOARD_FAILURE };
};

export function getDashboardUserDtos() {
  return (dispatch: Dispatch) => {
    dispatch(request());
    usersDtosApi().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };
  function success(data: TUserDto[]) {
    return { type: GET_DASHBOARD_USERDTOS_SUCCESS, payload: data };
  }
}

export function deleteDashboardUser(userId: string) {
  return (dispatch: Dispatch) => {
    dispatch(request());
    userDeleteApi(userId).then(
      (data) => {
        dispatch(success(userId));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };
  function success(data: any) {
    return { type: DELETE_DASHBOARD_USERDTOS_SUCCESS, payload: data };
  }
}

export function createDashboardUser(data: TUserEntity) {
  return (dispatch: Dispatch) => {
    dispatch(request());
    userCreateApi(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure());
      }
    );
  };
  function success(data: any) {
    return { type: CREATE_DASHBOARD_USERDTOS_SUCCESS, payload: data };
  }
}  
