import {
  ADD_NEW_BROKER_SUCCESS,
  ADD_NEW_TRADE_PLAY_SUCCESS,
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  GET_BROKER_ACCOUNT_LIST_SUCCESS,
  GET_PLAYS_LIST_SUCCESS,
} from "./actionType";

const initialState = {
  loading: false,
  hasError: false,
  accountData: [],
  tradePlayData: [],
};

export function dashboardTradeReducer(state = initialState, action: any) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case GET_BROKER_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        accountData: action.payload,
      };
    case ADD_NEW_BROKER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case ADD_NEW_TRADE_PLAY_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case GET_PLAYS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        tradePlayData: action.payload,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
