import axiosServices from "utils/axios";
import base64 from "base-64";
import { TUserEntity } from "types/users";

const username = process.env.REACT_APP_USERNAME;
const password = process.env.REACT_APP_PASSWORD;

export const usersDtosApi = async () => {
  const response = await axiosServices.get("/v1/api/user/find-all", {
    headers: {
      Authorization: "Basic " + base64.encode(username + ":" + password),
    },
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const userCreateApi = async (data : TUserEntity) => {
  const payload = {
    password: data.password,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    address: data.address 
  }
  const response = await axiosServices.post("/v1/api/user/sign-up", {

    headers: {
      Authorization: "Basic " + base64.encode(username + ":" + password),
         
    },
    data: {payload,},
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const userDeleteApi = async (userId : string) => {
  const response = await axiosServices.delete(`/v1/api/user/${userId}`, {
    headers: {
      Authorization: "Basic " + base64.encode(username + ":" + password),
    },
  });
  if (response.status === 200) {
    return response.data;
  }
};
