import { DASHBOARD_FAILURE, DASHBOARD_REQUEST, GET_SIGNAL_SEARCH_DATA } from "./actionType";

const initialState = {
  loading: false,
  signalData: [],
  hasError: false,

  // entityValueData: [],
  // entityStatData: [],
  // identSignalData: [],
  // identVarsData: [],
};

export function signalSearchReducer(state = initialState, action: any) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case GET_SIGNAL_SEARCH_DATA:
      return {
        ...state,
        loading: false,
        hasError: false,
        signalData: action.payload
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
