// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconDashboard } from "@tabler/icons";

import { NavItemType } from "types";

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDashboard,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
// https://codedthemes.gitbook.io/berry/

const development: NavItemType = {
  id: "sample-docs-roadmap",
  title: <FormattedMessage id="In Development" defaultMessage="In Development" />,
  icon: IconBrandChrome,
  type: "group",
  children: [
   
    {
      id: "stream-dashboard-test",
      title: <FormattedMessage id="Chart Gallery" defaultMessage="Chart Gallery" />,
      type: "item",
      url: "/stream-dashboard-test",
      icon: icons.IconSitemap,
    },
   
   
  ],
};

export default development;
