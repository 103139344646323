import { createRoot } from "react-dom/client";

// third party
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// project imports
import App from "App";
import { BASE_PATH } from "config";
import { ConfigProvider } from "contexts/ConfigContext";
import reportWebVitals from "reportWebVitals";
import * as serviceWorker from "serviceWorker";
import { store } from "store";

// style + assets
import "assets/scss/style.scss";

import { LicenseInfo } from '@mui/x-data-grid-pro';

 LicenseInfo.setLicenseKey('6d8a3f722a9a0abd8ba9e6c8c3a48941Tz05MjMxOSxFPTE3NDk4MDUxMzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');



// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <ConfigProvider>
      <BrowserRouter basename={BASE_PATH}>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
