import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, Typography } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { dispatch, useSelector } from "store";
import { TUserDto, TUserEntity } from "types/users";


import { DataGridPro, GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { createDashboardUser, deleteDashboardUser, getDashboardUserDtos } from "store/UsersDashborad/action";

const columns: any[] = [
  { field: "userId", headerName: "User Id", width: 175 },
  { field: "firstName", headerName: "First Name", width: 175 },
  { field: "lastName", headerName: "Last Name", width: 175 },
  { field: "email", headerName: "Email", width: 175 },
  { field: "address", headerName: "Address", width: 300 },
];

const UserManagement = () => {
  const { loadingUsers, usersData, userHasError } = useSelector((state) => state.userDashboard);
  // Signals Filter
  const [users, setusers] = useState<TUserDto[]>([]);
  const [user, setUser] = useState<TUserEntity>();

  const [selectedUsers, setSelectedUsers] = useState<TUserDto[]>([]);

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = async () => {
    dispatch(getDashboardUserDtos());
  }; 

  useEffect(() => {
    setusers(usersData);
  }, [usersData]);


  const usersDeleteHandle = () => {
    if(selectedUsers.length > 0) {
      selectedUsers.forEach((user)=>{
        dispatch(deleteDashboardUser(user.userId));
      });
      dispatch(getDashboardUserDtos());
    }
  };


  return (
    <>
      {userHasError && (
        <Alert severity="error" sx={{ width: "100%", marginBottom: "20px" }}>
          Something went wrong!
        </Alert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <MainCard
            title={
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3">User Management</Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button variant="contained"  
                        //  onClick={() => {usersDeleteHandle();}}
                      >
                        Add User
                      </Button>
                    </Grid>
                    <Grid item>  
                      {selectedUsers.length > 0 && (
                        <Button variant="contained" 
                          onClick={() => {usersDeleteHandle();}} 
                        >
                          Delete User
                        </Button>                 
                      )}                  
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          >
            <div style={{ padding:0,width: "100%", height: "63vh" }}>
              <DataGridPro
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  let selectedIDs = new Set(newRowSelectionModel);
                  let selectedRowData = users.filter((user) => {
                    return selectedIDs.has(user.userId)
                  }
                  );
                  setSelectedUsers(selectedRowData)
                }}
                // style={{ height: 360, width: '100%' }}
                rows={usersData}
                getRowId={(users) => users.userId}
                columns={columns}
                disableColumnMenu
                checkboxSelection
                loading={loadingUsers}
                // Pro
                disableColumnSelector
                disableColumnReorder
                pagination={true}
                pageSizeOptions={[10, 20, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { page: 0, pageSize: 10 } },
                }}
                autoHeight={false}
              />
            </div>
            {/* <DashboardDataGrid data={usersData} column={columns} loading={loadingUsers}  /> */}
          </MainCard>
        </Grid>
      </Grid >
    </>
  );
};

export default UserManagement;

