import { IconChartCandle, IconChartLine, IconDashboard, IconIdBadge2, IconRadar2, IconSearch } from "@tabler/icons";
import { FormattedMessage } from "react-intl";
import { NavItemType } from "types";

const icons = {
  IconStreamDashboard: IconDashboard,
  IconEntityDashboard: IconIdBadge2,
  IconTradeDashboard: IconChartCandle,
  IconStreamBlueprint: IconChartLine,
  IconsStreamScanning: IconSearch,
  IconsSignalSearch: IconRadar2,
};

<IconDashboard color="blue"/>

const dashboard: NavItemType = {
  id: "pages",
  title: <FormattedMessage id="Dunkware Stream" defaultMessage="US Stocks" />,
  type: "group",
  children: [
    {
      id: "stream-dashboard",
      title: <FormattedMessage id="Compute Dashboard" defaultMessage="Compute Dashboard" />,
      type: "item",
      url: "/stream-dashboard",
      icon: icons.IconStreamDashboard,
    },
    {
      id: "stream-signals",
      title: <FormattedMessage id="Stream Blueprint" defaultMessage="Stream Blueprint" />,
      type: "item",
      url: "/stream-signals",
      icon: icons.IconStreamBlueprint,
    },
    
   
    {
      id: "entity-dashboard",
      title: <FormattedMessage id="Stream Entities" defaultMessage="Stream Entities" />,
      type: "item",
      url: "/entity-dashboard",
      icon: icons.IconEntityDashboard,
    },
    // {
    //   id: "entity-dashboard",
    //   title: <FormattedMessage id="Stream Signals" />,
    //   type: "item",
    //   url: "/entity-dashboard",
    //   icon: icons.IconEntityDashboard,
    // },
    //{
    //  id: "stream-scanning",
     // title: <FormattedMessage id="Entity Scanning" />,
    //  type: "item",
    //  url: "/stream-scanning",
    //  icon: icons.IconsStreamScanning,
   // },
    {
      id: "stream-signal",
      title: <FormattedMessage id="Stream Signals" defaultMessage="Stream Signals" />,
      type: "item",
      url: "/stream-signal",
      icon: icons.IconsSignalSearch,
    }
  ],
};

export default dashboard;
