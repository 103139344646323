// project import
import dashboard from "./dashboard";
import trading from "./trading"
import system from "./system";
import development from "./development";
// types
import { NavItemType } from "types";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, trading, system,development],
};

export default menuItems;
