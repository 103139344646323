import { DASHBOARD_FAILURE, DASHBOARD_REQUEST, GET_DASHBOARD_STATS_SUCCESS, GET_STATUS_SUCCESS, UPDATE_STATUS_SUCCESS } from "./actionType";

const initialState = {
  loading: false,
  hasError: false,
  stats: { entityCount: 0, nodes: 0, status: "Terminate", tasksCompleted: 0, tasksExpired: 0, tasksPending: 0, tickCount: 0 },
  status: "Running"
};

export function dashboardStreamReducer(state = initialState, action: any) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        stats: action.payload,
        status: action.payload.status,
      };
    case UPDATE_STATUS_SUCCESS:
    case GET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        status: action.payload,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
