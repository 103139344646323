// routing
import Routes from "routes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import Locales from "ui-component/Locales";
// import RTLLayout from 'ui-component/RTLLayout';
import ThemeCustomization from "themes";
import Snackbar from "ui-component/extended/Snackbar";




// auth provider
import { ConfigProvider } from "contexts/ConfigContext";
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (

  <ConfigProvider>  
    <ThemeCustomization>
      <Locales>
        <NavigationScroll>
          <AuthProvider>
            <>
              <Routes />
              <Snackbar />
            </>
          </AuthProvider>
        </NavigationScroll>
      </Locales>
    </ThemeCustomization>
  </ConfigProvider>
);

export default App;
