// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDashboard,
  IconChartCandle,
  IconChartLine,
  IconIdBadge2,
  IconRadar2,
  IconSearch,
} from "@tabler/icons";

import { NavItemType } from "types";

const icons = {
  IconStreamDashboard: IconDashboard,
  IconEntityDashboard: IconIdBadge2,
  IconTradeDashboard: IconChartCandle,
  IconStreamBlueprint: IconChartLine,
  IconsStreamScanning: IconSearch,
  IconsSignalSearch: IconRadar2,
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconDashboard,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
// https://codedthemes.gitbook.io/berry/

const trading: NavItemType = {
  id: "sample-docs-roadmap",
  title: <FormattedMessage id="Manage Trading" defaultMessage="Manage Trading" />,
  icon: IconBrandChrome,
  type: "group",
  children: [
    {
      id: "trade-dashboard",
      title: <FormattedMessage id="Broker Connectors" defaultMessage="Broker Connectors" />,
      type: "item",
      url: "/trade-dashboard/brokers",
      icon: icons.IconTradeDashboard,
    },
    {
      id: "trading-systems",
      title: <FormattedMessage id="Capital Management" defaultMessage="Capital Management" />,
      type: "item",
      url: "/trading-systems",
      icon: icons.IconHelp,
      // external: true,
      // target: true,
    },
    {
      id: "documentation",
      title: <FormattedMessage id="Trading Systems" defaultMessage="Trading Systems" />,
      type: "item",
      url: "/trade-play",
      icon: icons.IconHelp,
      // external: true,
      // target: true,
    },
  ],
};

export default trading;
