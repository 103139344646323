import {
  ADD_QUERY_SUCCESS,
  DASHBOARD_FAILURE,
  DASHBOARD_REQUEST,
  DELETE_QUERY_SUCCESS,
  EDIT_QUERY_SUCCESS,
  GET_DASHBOARD_ENTITIES_SUCCESS,
  GET_DASHBOARD_ENTITY_STATS_SUCCESS,
  GET_DASHBOARD_ENTITY_VALUE_SUCCESS,
  GET_IDENTIFIER_SIGNAL_SUCCESS,
  GET_IDENTIFIER_VARIABLE_SUCCESS,
} from "./actionType";

const initialState = {
  loading: false,
  hasError: false,
  entityValueData: [],
  entityStatData: [],
  entitiesData: [],
  identSignalData: [],
  identVarsData: [],
};

export function dashboardEntityReducer(state = initialState, action: any) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case GET_DASHBOARD_ENTITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        entitiesData: action.payload,
      };
    case GET_DASHBOARD_ENTITY_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        entityValueData: action.payload,
      };
    case GET_DASHBOARD_ENTITY_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        entityStatData: action.payload,
      };
    case GET_IDENTIFIER_SIGNAL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        identSignalData: action.payload,
      };
    case GET_IDENTIFIER_VARIABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        identVarsData: action.payload,
      };
    case ADD_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case EDIT_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case DELETE_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
